import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, Subject } from 'rxjs';

export interface IMyImunifyState {
    enabled: boolean;
    protection: boolean;
    purchase_page_url: string;
}

@Injectable()
export class MyImunifyState {
    changes$ = new BehaviorSubject<IMyImunifyState>({
        enabled: false,
        protection: false,
        purchase_page_url: '',
    });
    myImunifyStateSubscription: Subscription | undefined;
    isEnabledWithProtection$ = new Subject<void>();

    constructor() {}
}
