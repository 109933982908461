import { Component, ContentChildren, ElementRef, OnDestroy, QueryList } from '@angular/core';
import { combineLatest, identity, merge, NEVER, Observable, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { NewsItemDirective } from '../news-items/news-item.directive';


@Component({
    selector: 'i360-news-notifications',
    template: `
        <ng-container *ngIf="newsCount > 0">
            <mat-icon
                    fontSet="i360-icon"
                    fontIcon="i360-icon-bell"
                    id="i360-notification-events-button"
                    tabindex="0"
                    (keyup.enter)="toggleOverlay()"></mat-icon>
            <div class="notification-events-count" *ngIf="unreadNewsCount > 0">
                {{ unreadNewsCount }}
            </div>
            <i360-news-overlay *ngIf="overlayVisible">
                <ng-container *ngFor="let news of _news"
                              [ngTemplateOutlet]="news.template">
                </ng-container>
            </i360-news-overlay>
        </ng-container>
    `,
    styleUrls: ['news-notifications.component.scss'],
})
export class NewsNotificationsComponent implements OnDestroy {
    newsCount: number = 0;
    unreadNewsCount: number = 0;
    _news: NewsItemDirective[] = [];
    overlayVisible: boolean = false;
    overlayAutoOpenBlocked: boolean = false;
    private unsubscribe: Subject<boolean> = new Subject();

    constructor(
        public element: ElementRef,
    ) {}

    @ContentChildren(NewsItemDirective)
    set news(value: QueryList<NewsItemDirective>) {
        this._news = value.toArray();
        this.unsubscribe.next(true);

        this.updateNewsCount();
        this.updateUnreadNewsCount();
        this.updateOverlayAutoOpenBlocked();
        this.openOverlayIfNeeded();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }

    toggleOverlay() {
        if (this.newsCount === 0) return;

        this.overlayVisible = !this.overlayVisible;

        if (!this.overlayVisible) {
            this._news.forEach((news) => {
                news.markAsRead();
            });
        }
    }

    private combineLatestUntilUnsubscribed(input: Array<Observable<boolean>>) {
        return combineLatest(input).pipe(takeUntil(this.unsubscribe));
    }

    private updateNewsCount() {
        this.combineLatestUntilUnsubscribed(
            this._news.map((news) => news.isCountable),
        ).subscribe((countableStatuses) => {
            this.newsCount = countableStatuses.filter(identity).length;
        });
    }

    private updateUnreadNewsCount() {
        this.combineLatestUntilUnsubscribed(
            this._news.map((news) => news.isUnread),
        ).subscribe((unreadStatuses) => {
            this.unreadNewsCount = unreadStatuses.filter(identity).length;
        });
    }

    private updateOverlayAutoOpenBlocked() {
        this.combineLatestUntilUnsubscribed(
            this._news.map((news) => news.blockOverlayAutoOpen),
        ).subscribe((blockStatuses) => {
            this.overlayAutoOpenBlocked = blockStatuses.some(identity);
        });
    }

    private openOverlayIfNeeded() {
        // We need NEVER because some NewsItem's openOverlay may complete immediately
        // which lead to merge's completion and debounceTime next and complete calls
        // and we get ExpressionChangedAfterItHasBeenCheckedError
        merge(NEVER, ...this._news.map((news) => news.openOverlay))
            .pipe(
                filter(identity),
                debounceTime(0),
                takeUntil(this.unsubscribe),
            )
            .subscribe(() => {
                if (!this.overlayAutoOpenBlocked) {
                    this.overlayVisible = true;
                }
            });
    }
}
