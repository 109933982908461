import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from 'app/utils/translate/translate.service';
import { UpgradeButtonService } from 'app/directives/upgrade-button/upgrade-button.service';
import { Subscription, Subject, race, timer, Observable, throwError, of } from 'rxjs';
import { takeUntil, take, switchMap, catchError } from 'rxjs/operators';
import { NavigationComponent } from 'app/utils/navigation/navigation.component';

const ENGLISH = 'en';

@Directive({
    selector: '[i360UpgradeButton]'
})
export class UpgradeButtonDirective implements OnInit, OnDestroy {
    @Input() i360UpgradeButton: string;

    private titleSub: Subscription;
    private abText: string;
    private isSentViewEvent: boolean = false;
    private destroy$ = new Subject<void>();

    constructor(
        private upgradeButtonService: UpgradeButtonService,
        private translate: TranslateService,
        private el: ElementRef,
        private renderer: Renderer2,
        private navigation: NavigationComponent
    ) {
    }

    ngOnInit(): void {
        this.setDisplay('none');
        this.translate.translate.onLangChange
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.renderButtonText();
            });

        this.renderButtonText();
    }

    ngOnDestroy(): void {
        this.titleSub.unsubscribe();
        this.destroy$.next();
    }

    @HostListener('click') onClick() {
        if (this.needABTest && this.abText) {
            this.upgradeButtonService.sendABEventClick(this.abText)
                .pipe(take(1)).subscribe();
        }
    }

    private renderButtonText(): void {
        this.titleSub?.unsubscribe();
        this.titleSub = this.getUpgradeButtonText().subscribe((title) => {
            this.setDisplay('block');
            this.setText(title);
            this.navigation.rebuild();

            if (this.needABTest && this.abText && !this.isSentViewEvent) {
                this.upgradeButtonService.sendABEventView(this.abText)
                    .pipe(take(1)).subscribe(() => { this.isSentViewEvent = true; });
            }
        });
    }

    private getUpgradeButtonText(): Observable<string> {
        if (this.needABTest) {
            return race(
                this.upgradeButtonService.getABText().pipe(
                    switchMap(text => {
                        if (!text) {
                            return throwError(null);
                        }

                        this.abText = text;
                        return of(text);
                    }),
                    catchError(() => this.translate.stream(this.i360UpgradeButton))
                ),
                timer(3000).pipe(switchMap(() => this.translate.stream(this.i360UpgradeButton)))
            );
        }

        return this.translate.stream(this.i360UpgradeButton);
    }

    private setText(title: string): void {
        this.renderer.setProperty(this.el.nativeElement, 'innerText', title);
    }

    private setDisplay(value: string): void {
        this.renderer.setStyle(this.el.nativeElement, 'display', value);
    }

    private get needABTest(): boolean {
        return this.translate.currentLang === ENGLISH;
    }
}
