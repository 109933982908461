import { Injectable } from '@angular/core';
import { EmailsApi } from '@imunify360-api/emails';
import { EmailItem } from '@imunify360-api/emails';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { cache } from 'app/utils/cache';
import { HttpErrorResponse } from '@angular/common/http';
import { XHR } from 'app/utils/xhr/xhr';

declare const i360ieExist: any;


@Injectable({
    providedIn: 'root',
})
export class EmailsService implements CanActivate {
    constructor(
        private router: Router,
        public xhr: XHR,
    ) {}

    private emailFilterBy: {
        sender: string,
        account_name?: string,
        any: string,
        recipients: string,
        received_date_from: string,
        received_date_to: string,
        reason: string,
        subject: string,
    } = {
        sender: 'emails.quarantine.headers.sender',
        account_name: 'emails.quarantine.headers.account',
        reason: 'emails.quarantine.filters.reason',
        recipients: 'emails.quarantine.headers.recipients',
        any: 'emails.quarantine.headers.any',
        received_date_from: 'emails.quarantine.filters.receivedDateFrom',
        received_date_to: 'emails.quarantine.filters.receivedDateTo',
        subject: 'emails.quarantine.headers.subject',
    };
    list = this.xhr.wrap(this.xhr.rx(EmailsApi.list), EmailItem);
    editEmails = this.xhr.request(EmailsApi.editEmails);
    viewEmail = this.xhr.request(EmailsApi.viewEmail);
    emailVersions: Observable<{
        version: string,
        components: string,
    } | null> = this.xhr.rx(EmailsApi.imunifyEmailVersion, false, false)()
        .pipe(
            map(result => {
                let components: string[] = [];
                if (result.data.items) {
                    components = result.data.items.components.map((component) => {
                        return component.name + ' - ' + component.version;
                    })
                }
                return {
                    version: result.data.items.version,
                    components: components.join('\r\n')
                };
            }),
            cache(300),
            catchError((e: HttpErrorResponse) => {
                return  of(null);
            }),
        );

    getAvailableKeys(isAdmin: boolean) {
        if (!isAdmin && this.emailFilterBy.account_name) {
            delete this.emailFilterBy.account_name;
        }
        return this.emailFilterBy;
    }

    canActivate(): Observable<boolean> | boolean {
        if (this.imunifyEmailExist()) {
            return true;
        } else {
            this.router.navigate(['/']);
            return false;
        }
    }

    imunifyEmailExist = () => typeof i360ieExist !== 'undefined' && i360ieExist === '1';
}
