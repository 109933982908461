import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AgentResponse, Result } from '@imunify360-api/misc';
import { NotificationsService } from 'app/services/notifications';
import { UntranslatableString } from 'app/utils/translate/translate.service';
import { Type } from '@angular/core';
import { LicenseState } from 'app/services/license-state';

@Injectable({
    providedIn: 'root',
})
export class XhrNotificator {

    constructor(
        private injector: Injector,
        private notifications: NotificationsService,
    ) {}

    showResponseErrors(error: AgentResponse | HttpErrorResponse) {
        if ('message' in error) {
            let content = (new DOMParser()).parseFromString(error.error, 'text/html')['body']
                .innerText;
            if (content === '[object Object]') {
                console.log(error);
                content = 'System error';
            }
            this.notifications.error(UntranslatableString.for(content.trim()),
                `notifications.${Result.ERROR}`);
        } else if ('messages' in error) {
            let license = {};
            try {
                const LicenseService = require('app/services/license').LicenseService;
                license = this.injector
                    .get<LicenseState>(LicenseState as Type<LicenseState>).license.value;
            } catch (e) {
                // license service is not available in login module
            }
            for (let msg of error.messages) {
                this.notifications.error(msg, `notifications.${error.result}`,
                    license);
            }
        }
    }
}
