import { NgModule } from '@angular/core';
import { ModalContentComponent } from './content/content.component';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { ModalService } from './modal.service';
import { ConfirmComponent } from './confirm/confirm.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { TempDialogTemplateWrapperDirective } from './temp-dialog-template-wrapper.directive';
import { CheckboxesComponent } from './checkboxes/checkboxes.component';
import { ModalItemsComponent } from './items/items.component';
import { ModalDetailsComponent } from './modal-details/modal-details.component';
import { I360TranslateModule } from 'app/utils/translate/translate.module';
import { MatTooltipModule } from '@angular/material/tooltip';


const publicDirectives = [
    FileViewerComponent,
    ConfirmComponent,
    ModalContentComponent,
    TempDialogTemplateWrapperDirective,
    CheckboxesComponent,
    ModalItemsComponent,
    ModalDetailsComponent,
];
const privateDirectives = [

];

@NgModule({
    imports: [
        MatDialogModule,
        MatButtonModule,
        CommonModule,
        A11yModule,
        I360TranslateModule,
        MatTooltipModule,
    ],
    declarations: [
        ...publicDirectives,
        ...privateDirectives,
    ],
    exports: [
        ...publicDirectives,
    ],
    providers: [
        ModalService,
    ]
})
export class I360ModalModule {

}
