import { Pipe, PipeTransform } from '@angular/core';
import { TranslateParams, TranslateService } from 'app/utils/translate/translate.service';


@Pipe({
    name: 'i360Error',
})
export class ErrorPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    /**
     * Validator should return an error in format: { ruleName: params }
     *
     * @example {required: {what: 'Country'}}
     * @example {integer: {}}
     */
    transform(value: null|{[key: string]: TranslateParams}) {
        if (!value) return '';

        const firstKey = Object.keys(value)[0];
        return this.translate.translate.get(`validators.${firstKey}`, value[firstKey]);
    }
}
