<i360-navigation #navigation
                 *ngIf="(userRole !== authState.roles.none) && (appState.imunifyStatus|async) === AgentStatus.OK">
    <ng-container *ngIf="imunifyPackage === '360'">
        <a [routerLink]="['']"
           attr.aria-label="{{ 'menu.logo' | translate}}"
           *i360Template="'logo'"
           data-element="test-imunify360-logo"
           class="i360-logo">
            <mat-icon svgIcon="logo-myimunify"
                      *ngIf="isClient && myImunifyState?.enabled; else standardLogo">
            </mat-icon>
            <ng-template  #standardLogo>
                <mat-icon svgIcon="logo-imunify"></mat-icon>
            </ng-template>
        </a>
        <ng-container *ngIf="isClient && myImunifyState?.enabled && !myImunifyState.protection">
            <button i360-button
                    [primary]="true"
                    class="upgrade-button"
                    (click)="myImunifyService.openUpgradePage()"
                    *i360Template="'button'">
                {{ 'menu.getProtected' | translate }}
            </button>
        </ng-container>
        <ng-container *ngIf="!((licenseState.license|async)?.isFailed()) && !(licenseState.eulaFailed|async) && (appState.rpcReady$|async)">
            <ng-container *ngIf="isAdmin">
                <a [routerLink]="[imunifyPackage, (authState.role|async), 'dashboard']"
                   *i360Template
                   routerLinkActive="active">
                    {{ 'menu.dashboard' | translate }}
                </a>
                <a [routerLink]="[imunifyPackage, (authState.role|async), 'incidents']"
                   *i360Template
                   routerLinkActive="active">
                    {{ 'menu.incidents' | translate }}
                </a>
                <ng-container *ngIf="firewallService.isFirewallEnabled()|async">
                    <a [routerLink]="[imunifyPackage, (authState.role|async), 'firewall']"
                       *i360Template
                       routerLinkActive="active">
                        {{ 'menu.firewall' | translate }}
                    </a>
                </ng-container>
                <ng-container *ngIf="emailsService.imunifyEmailExist()">
                    <a [routerLink]="[imunifyPackage, (authState.role|async), 'email']"
                       *i360Template
                       routerLinkActive="active">
                        {{ 'menu.email' | translate }}
                    </a>
                </ng-container>
                <a [routerLink]="[imunifyPackage, (authState.role|async), 'malware']"
                   *i360Template
                   routerLinkActive="active">
                    {{ 'menu.malwareScanner' | translate }}
                </a>
                <a [routerLink]="[imunifyPackage, (authState.role|async), 'proactive-defense']"
                   *i360Template
                   routerLinkActive="active">
                    {{ 'menu.proactiveDefense' | translate }}
                </a>
                <a [routerLink]="[imunifyPackage, (authState.role|async), 'reputation-management']"
                   *i360Template
                   routerLinkActive="active">
                    {{ 'menu.reputationManagement' | translate }}
                    <span class="i360-nav-badge"
                          (cdkObserveContent)="navigation.forceNormalize.next()"
                          *ngIf="requestsState.infectedDomainsCount.cache|async">
                        {{ requestsState.infectedDomainsCount.cache|async }}
                    </span>
                </a>
                <a [routerLink]="[imunifyPackage, (authState.role|async), 'kernelcare']"
                   *i360Template
                   routerLinkActive="active">
                    {{ 'menu.kernelCare' | translate }}
                </a>
                <ng-template i360Template="right">
                    <i360-news></i360-news>
                </ng-template>
                <ng-container *ngIf="support|async">
                    <ng-template i360Template="right">
                        <a routerLinkActive="active"
                           attr.aria-label="{{ 'menu.support' | translate }}"
                           [routerLink]="[imunifyPackage, (authState.role|async), 'support']">
                            <mat-icon class="support" svgIcon="support"></mat-icon>
                        </a>
                    </ng-template>
                </ng-container>
                <a class="settings-button"
                   routerLinkActive="active"
                   attr.aria-label="{{ 'menu.settings' | translate }}"
                   *i360Template="'right'"
                   [routerLink]="[imunifyPackage, (authState.role|async), 'settings']">
                    <i class="material-icons hidden-xs">settings</i>
                </a>
            </ng-container>
            <ng-container *ngIf="isClient">
                <ng-container *ngIf="requestsState.hasAv|async">
                    <a [routerLink]="[imunifyPackage, (authState.role|async), 'malware']"
                       *i360Template
                       routerLinkActive="active">
                        {{ 'menu.malwareScanner' | translate }}
                    </a>
                </ng-container>
                <ng-container *ngIf="emailsService.imunifyEmailExist()">
                    <a [routerLink]="[imunifyPackage, (authState.role|async), 'email']"
                       *i360Template
                       routerLinkActive="active">
                        {{ 'menu.email' | translate }}
                    </a>
                </ng-container>
                <ng-container *ngIf="requestsState.hasProactive|async">
                    <a [routerLink]="[imunifyPackage, (authState.role|async), 'proactive-defense']"
                       *i360Template
                       routerLinkActive="active">
                        {{ 'menu.proactiveDefense' | translate }}
                    </a>
                </ng-container>
                <a class="settings-button"
                   routerLinkActive="active"
                   attr.aria-label="{{ 'menu.settings' | translate }}"
                   *i360Template="'right'"
                   [routerLink]="[imunifyPackage, (authState.role|async), 'settings']">
                    <i class="material-icons hidden-xs">settings</i>
                </a>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="imunifyPackage === 'AV'">
        <a [routerLink]="['']"
           attr.aria-label="{{ 'menu.logo' | translate }}"
           *i360Template="'logo'"
           class="i360-logo"
           data-element="test-imunifyAV-logo"
           [class.imunifyav-logo]="(licenseState.license|async)?.product === Product.imunifyAV"
           [class.imunifyavplus-logo]="(licenseState.license|async)?.product === Product.imunifyAVPlus">
            <mat-icon svgIcon="logo-imunifyAV{{(licenseState.license|async)?.product === Product.imunifyAVPlus ? 'Plus' : ''}}">
            </mat-icon>
        </a>

        <ng-container *ngIf="!licenseState.eula.value.isFailed() && (appState.rpcReady$|async)">
            <a [routerLink]="[imunifyPackage, (authState.role|async), 'malware']" *i360Template routerLinkActive="active">
                {{ 'menu.malwareScanner' | translate }}
            </a>
            <ng-container *ngIf="isAdmin">
                <a [routerLink]="[imunifyPackage, (authState.role|async), 'reputation-management']"
                   *i360Template
                   routerLinkActive="active">
                    {{ 'menu.reputationManagement' | translate }}
                    <ng-container *ngIf="!(licenseState.license|async)?.isFreeVersion">
                        <span class="i360-nav-badge"
                              *ngIf="requestsState.infectedDomainsCount.cache|async"
                              (cdkObserveContent)="navigation.forceNormalize.next()">
                            {{ requestsState.infectedDomainsCount.cache|async }}
                        </span>
                    </ng-container>
                </a>

                <ng-container *ngIf="firewallService.isFirewallEnabled()|async">
                    <a [routerLink]="[imunifyPackage, (authState.role|async), 'firewall']" *i360Template routerLinkActive="active">
                        {{ 'menu.firewall' | translate }}
                    </a>
                </ng-container>

                <a [routerLink]="[imunifyPackage, (authState.role|async), 'proactive-defense']" *i360Template routerLinkActive="active">
                    {{ 'menu.proactiveDefense' | translate }}
                </a>

                <a [routerLink]="[imunifyPackage, (authState.role|async), 'kernelcare']"
                   *i360Template
                   routerLinkActive="active">
                    {{ 'menu.kernelCare' | translate }}
                </a>

                <i360-news *i360Template="'right'"></i360-news>
                <a [routerLink]="[imunifyPackage, (authState.role|async), 'support']"
                   attr.aria-label="{{ 'menu.support' | translate }}"
                   *i360Template="'right'"
                   routerLinkActive="active">
                    <mat-icon class="support" svgIcon="support"></mat-icon>
                </a>

                <a class="settings-button"
                   routerLinkActive="active"
                   attr.aria-label="{{ 'menu.settings' | translate }}"
                   *i360Template="'right'"
                   [routerLink]="[imunifyPackage, (authState.role|async), 'settings']">
                    <i class="material-icons hidden-xs">settings</i>
                </a>

                <ng-container *ngIf="(licenseState.license|async)?.upgradeAvailable && (config.changes|async)?.PERMISSIONS.upgrade_button">
                    <button i360-button
                            i360UpgradeButton="menu.upgrade"
                            [round]="true"
                            [primary]="true"
                            class="upgrade-button"
                            routerLinkActive="active"
                            *i360Template="'button'"
                            [routerLink]="[imunifyPackage, (authState.role|async), 'upgrade']">
                    </button>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container>
        <i360-menu-profile *i360Template="'right'"></i360-menu-profile>
    </ng-container>
</i360-navigation>

<notifications></notifications>
