import { Injectable } from '@angular/core';
import { FileAPI } from '@imunify360-api/file';
import { Result } from '@imunify360-api/misc';
import { map } from 'rxjs/operators';
import { of ,  Observable } from 'rxjs';
import { XHR } from 'app/utils/xhr/xhr';


@Injectable()
export class FileService {
    constructor(
        public xhr: XHR,
    ) {}

    public limit: number = FileAPI.CHUNK_LENGTH;
    public offset: number = 0;
    public notifyOnError = true;

    load = this.xhr.request(FileAPI.load,
        this.notifyOnError, this.notifyOnError);
    private _load = this.xhr.rx(FileAPI.load,
        this.notifyOnError, this.notifyOnError);

    loadChunk(logFile) {
        return this._load({
            path: logFile,
            offset: this.offset,
            limit: this.limit,
        }).pipe(
            map(response => {
                const data = response.data.data;
                if (data.offset === 0 && data.size > data.limit) {
                    this.offset = data.limit;
                    this.limit = (data.size - data.limit);
                } else {
                    this.offset = data.size;
                }

                return data.chunk.replace(/(?:\r\n|\r|\n)/g, '<br/>');
            }),
        );
    }

    public upload(files: File[] = []): Observable<{result: Result, data: string} | null> {
        let formData: FormData = new FormData();

        if (!files.length) {
            return of(null);
        }
        formData.append('command', 'uploadFile');
        for (let f of files) {
            formData.append('files[]', f);
        }

        return this.xhr.post(formData, () => null, this.notifyOnError, this.notifyOnError);
    }
}
