import {
    AgentResponse, AgentResponseData,
    DataFrom, ExtendedRequestParams,
    ListResponseData,
} from './misc';
import { cutOrderByParam } from './emails-settings';

export const enum requestMethod {
    get = 'get',
    post = 'post',
    delete = 'delete',
    put = 'put',
}

export const enum operationType {
    delete = 'delete',
    release = 'release',
}

export const requestCommandIE = 'commandIE';

function buildScriptPath(scriptHeader): string {
    let scriptPath = '';
    if (scriptHeader) {
        if (scriptHeader.domain) {
            scriptPath = scriptHeader.domain;
        }
        if (scriptHeader.path) {
            if (scriptPath) {
                scriptPath += ': ';
            }
            scriptPath += scriptHeader.path;
        }
    }
    return scriptPath;
}

export class EmailItem {
    public emailId: string;
    public accountName: string;
    public reasons: string[];
    public sender: string;
    public recipients: string[];
    public subject: string;
    public receivedDate: number;
    public scriptPath?: string;

    constructor(item: ResponseEmailItem) {
        this.emailId = item.email_id;
        this.accountName = item.account_name;
        this.reasons = item.reasons;
        this.recipients = item.recipients;
        this.sender = item.sender;
        this.subject = item.subject;
        this.receivedDate = item.received_date;
        this.scriptPath = buildScriptPath(item.script_header);
    }
}

export class EmailDetails {
    public emailId: string;
    public accountName: string;
    public sizeBytes: number;
    public sender: string;
    public recipients: string[];
    public reasons: string[];
    public subject: string;
    public body: string;
    public header: {[key: string]: string};
    public receivedDate: number;
    public plainText: string;

    constructor(item: ResponseEmailDetails) {
        this.emailId = item.email_id;
        this.accountName = item.account_name;
        this.recipients = item.recipients;
        this.sender = item.sender;
        this.subject = item.subject;
        this.sizeBytes = item.size_bytes;
        this.body = item.body;
        this.header = item.header;
        this.receivedDate = item.received_date;
        this.plainText = (item.raw) ? item.raw : '';
    }
}


export interface ResponseEmailItem {
    email_id: string;
    account_name: string;
    reasons: string[];
    sender: string;
    recipients: string[];
    subject: string;
    body: string;
    received_date: number;
    script_header?: {
        raw: string;
        path: string;
        domain: string;
    }
}

interface EmailPackagesVersion {
    release: string,
    name: string,
    version: string,
}
export interface ResponseEmailVersion {
    version: string,
    components: EmailPackagesVersion[]
}

export interface ResponseEmailDetails {
    email_id: string;
    account_name: string;
    size_bytes: number;
    reasons: string[];
    domain: string;
    sender: string;
    raw?: string;
    recipients: string[];
    subject: string;
    body: string;
    header: {[key: string]: string};
    received_date: number;
    plainText?: string;
}

export class EmailsApi {

    static list = (params) => <ExtendedRequestParams<DataFrom<EmailsListResponseData>>> {
        command: requestCommandIE,
        method: ['accounts', '{account_name}', 'emails'],
        params: {
            ...cutOrderByParam(params),
            request_method: requestMethod.get,
        },
    }

    static editEmails(params: {ids: string[], operation: operationType}) {
        return <ExtendedRequestParams>{
            command: requestCommandIE,
            method: ['accounts', '{account_name}', 'emails'],
            params: {
                ...params,
                request_method: requestMethod.put,
            },
        };
    }

    static viewEmail({emailId,raw}: {emailId: string, raw?: boolean}) {
        return <ExtendedRequestParams<DataFrom<EmailsResponseData>>>{
            command: requestCommandIE,
            method: ['accounts', '{account_name}', 'emails', emailId],
            params: {
                ...(raw ? {raw: 'true'} : {}),
                 request_method: requestMethod.get,
            },
        };
    }

    static imunifyEmailVersion() {
        return <ExtendedRequestParams<DataFrom<EmailsVersionResponse>>>{
            command: requestCommandIE,
            method: ['version'],
            params: {
                 request_method: requestMethod.get,
            },
        };
    }
}

export type EmailsListResponseData = AgentResponse<ListResponseData<ResponseEmailItem>>;
export type EmailsVersionResponse = AgentResponse<AgentResponseData<ResponseEmailVersion>>
export type EmailsResponseData = AgentResponse<AgentResponseData<ResponseEmailDetails>>
    | AgentResponse<ListResponseData<ResponseEmailDetails>>;
