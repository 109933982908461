import { Injectable } from '@angular/core';
import { SettingsAPI } from '@imunify360-api/settings';
import { NotificationSettingsAPI } from '@imunify360-api/notification-settings';
import { XHR } from 'app/utils/xhr/xhr';


@Injectable()
export class SettingsService {
    constructor(public xhr: XHR) {}

    show = this.xhr.rx(SettingsAPI.show);
    saveSettings = this.xhr.rx(SettingsAPI.save);
    notificationShow = this.xhr.rx(NotificationSettingsAPI.show);
    saveNotificationSettings = this.xhr.rx(NotificationSettingsAPI.save);
    conflicts = this.xhr.rx(SettingsAPI.conflicts);
}
