import { forwardRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import {
    AbstractControl, ControlValueAccessor,  NgModel, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator,
    Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { I360Validators } from 'app/utils/validator/validators';


@Component({
    selector: 'i360-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true,
        },
    ],
})
export class DatepickerComponent implements ControlValueAccessor, Validator {
    @Input() placeholder = 'utils.datepicker.placeholder';
    @ViewChild('input', {static: true}) input: ElementRef;
    @ViewChild(NgModel, {static: true}) inputNgModel: NgModel;
    maxDays = 24;
    ttl: number | undefined;
    type: DatepickerUnit;
    datepickerUnit = DatepickerUnit;
    validator;
    propagateChange: Function = () => {};

    constructor() {
        this.type = this.datepickerUnit.Days;
        this.setValidator();
    }

    writeValue(value: number) {
        if (value) {
            const diff = (timestamp: moment.Moment, type: DatepickerUnit) =>
                Math.round(timestamp.diff(moment(), type, true));
            const timestamp = moment.unix(value);
            const hours = diff(timestamp, this.datepickerUnit.Hours);
            if (hours < 24) {
                this.ttl = hours;
                this.type = this.datepickerUnit.Hours;
            } else {
                this.ttl = diff(timestamp, this.datepickerUnit.Days);
                this.type = this.datepickerUnit.Days;
            }
        } else {
            this.ttl = undefined;
            this.type = this.datepickerUnit.Days;
        }
    }

    validate(c: AbstractControl) {
        const validation = this.validator({value: this.ttl});
        this.inputNgModel.control.setErrors(validation);
        return validation;
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}

    setValidator() {
        this.validator = Validators.compose(
            [I360Validators.integer(false),
                Validators.min(1),
                Validators.max(
                    this.type === this.datepickerUnit.Days ? this.maxDays : this.maxDays * 24)]);
    }

    onChange(value: DatepickerUnit) {
        const expiration = this.ttl
            ? moment().add(this.ttl, this.type).unix()
            : null;

        this.setValidator();
        this.propagateChange(expiration);
    }
}

enum DatepickerUnit {
    Days = 'days',
    Hours = 'hours',
}
