import { Injectable } from '@angular/core';
import { identity } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AdminContactsOpenerService } from './admin-contacts-opener.service';
import {
    daysShouldPassPerNotification,
    sequentialNotificationsCount,
} from './news-admin-contacts.constants';

const msInDay = 24 * 60 * 60 * 1000;

@Injectable()
export class AdminContactsOpenerPeriodicalService extends AdminContactsOpenerService {
    openIfNeeded(): void {
        this.newsAdminContactsComponent.isCountable.pipe(
            take(1),
            filter(identity),
        ).subscribe(() => {
            const history = this.adminContactsHistoryService.getHistory();
            const lastOpenRecord = history[history.length - 1];
            const frequencyIndex = history.length % sequentialNotificationsCount;
            const daysShouldPass = daysShouldPassPerNotification[frequencyIndex];
            const msShouldPass = lastOpenRecord.timestamp + daysShouldPass * msInDay;

            if (Date.now() >= msShouldPass) {
                this.newsAdminContactsComponent.openOverlay.next(true);
                this.adminContactsHistoryService.addHistoryRecord({ timestamp: Date.now() });
            }
        });
    }
}
