import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    HostBinding,
    OnDestroy,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
    ChangeDetectorRef,
} from '@angular/core';
import { ImunifyTemplateDirective } from 'app/utils/table/template.directive';
import { fromEvent, timer, Subject, combineLatest, interval, EMPTY } from 'rxjs';
import { debounceTime, merge, switchMap, takeUntil } from 'rxjs/operators';
import { animate, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { MatMenuItem } from '@angular/material/menu';
import { I360Config } from 'app/utils/config/config';
import { RuleSet } from '@imunify360-api/settings';
import { AppState } from 'app/core/app.service';
import { Panel } from 'app/utils/panel';
import { LicenseState } from 'app/services/license-state';
import { LicenseService } from 'app/services/license';
import { AuthState } from 'app/services/auth-state';
import { AuthService, I360Role } from 'app/services/auth';
import { MyImunifyService } from 'app/services/my-imunify';
import { IMyImunifyState } from 'app/services/my-imunify-state';

export const updateTokenInterval = 150000;

@Component({
    selector: 'i360-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('navClosed', [
            // angular animations can not use media queries but can animate transition
            // css animations can use media queries but can not animate transitions...
            transition(':leave', [
                animate(0)
            ]),
            transition('* => *', [animate(600)]),
        ]),
    ],
})
export class NavigationComponent implements AfterContentInit, AfterViewInit, OnDestroy {
    @ViewChild('separator', {static: true}) separator: ElementRef;
    @ViewChild('navWrapper', {static: true}) navWrapper: ElementRef;
    @HostBinding('attr.role') readonly role = 'navigation';
    @ContentChildren(ImunifyTemplateDirective, {descendants: true})
    templates: QueryList<ImunifyTemplateDirective>
    @HostBinding('class.i360-nav-closed') @HostBinding('@navClosed') closed: boolean = true;
    leftTemplates: Array<TemplateRef<any>> = [];
    rightTemplates: Array<TemplateRef<any>> = [];
    buttonTemplates: Array<TemplateRef<any>> = [];
    dropTemplates: Array<TemplateRef<any>> = [];
    // https://github.com/angular/material.angular.io/issues/577
    copyOfDropTemplates: Array<TemplateRef<any>> = [];
    logoTemplate?: TemplateRef<any>;
    forceNormalize = new Subject;
    ruleSet = RuleSet;
    myImunifyState: IMyImunifyState;

    protection: string;

    private destroyed = new Subject;

    constructor(public elementRef: ElementRef,
                private cdr: ChangeDetectorRef,
                private translateService: TranslateService,
                public appState: AppState,
                public authState: AuthState,
                public config: I360Config,
                public licenseState: LicenseState,
                public licenseService: LicenseService,
                public panel: Panel,
                public authService: AuthService,
                private myImunifyService: MyImunifyService,
    ) {
        this.listenMyImunifyState();
    }

    addToMain(): void|true {
        if (this.dropTemplates.length) {
            let template = this.dropTemplates.shift();
            if (template) {
                this.leftTemplates.push(template);
            }
            return true;
        }
    }

    addToDrop(): void|true {
        if (this.leftTemplates.length) {
            let template = this.leftTemplates.pop();
            if (template) {
                this.dropTemplates.unshift(template);
            }
            return true;
        }
    }

    ngOnDestroy() {
        this.destroyed.next();
    }

    ngAfterContentInit() {
        this.rebuild();
        this.templates.changes.subscribe(() => this.rebuild());
    }
    rebuild() {
        this.leftTemplates.length = 0;
        this.rightTemplates.length = 0;
        this.buttonTemplates.length = 0;
        this.dropTemplates.length = 0;
        this.logoTemplate = undefined;
        this.templates.forEach(template => {
            switch (template.name) {
                case 'button':
                    this.buttonTemplates.push(template.template);
                    break;
                case 'right':
                    this.rightTemplates.push(template.template);
                    break;
                case 'logo':
                    this.logoTemplate = template.template;
                    break;
                default:
                    this.leftTemplates.push(template.template);
                    break;
            }
        });
        this.forceNormalize.next();
    }

    ngAfterViewInit() {
        let width: number;
        fromEvent(window, 'resize')
            .pipe(
                merge(this.forceNormalize),
                // merge(this.interval.interval10),
                merge(this.translateService.onLangChange),
                debounceTime(400),
                takeUntil(this.destroyed),
            ).subscribe(async () => {
                if (width !== this.getSeparatorWidth()) {
                    await this.normalize();
                    this.copyOfDropTemplates = [...this.dropTemplates];
                    await timer().toPromise(); // wait for change detection
                    width = this.getSeparatorWidth();
                }
        });
        if (this.panel.isNoPanel) {
            this.autoUpdateToken();
        }
    }

    autoUpdateToken() {
        combineLatest([
            this.authState.username,
            this.authState.role,
            interval(updateTokenInterval),
        ]).pipe(
            switchMap(([username, role, _]: [string, I360Role, number]) => {
                // TODO: remove this role check after DEF-16117 fix
                return !!username && role === I360Role.admin
                    ? this.licenseService.getToken({username}): EMPTY;
            }),
            takeUntil(this.destroyed),
        ).subscribe(response => {
            this.authService.setToken(response.data.items);
        });
    }

    clickMenuItem(matMenuItem: MatMenuItem) {
        matMenuItem['_elementRef'].nativeElement.querySelector('a')!.click();
    }

    async normalize() {
        let changed;
        do {
            changed = false;
            if (window.innerWidth < 768) {
                changed = this.addToMain();
            } else if (!this.isMainNavHeightOk()) {
                changed = this.addToDrop();
                await timer().toPromise(); // wait for change detection
            } else {
                changed = this.addToMain();
                await timer().toPromise(); // wait for change detection
                if (!this.isMainNavHeightOk()) {
                    this.addToDrop();
                    return;
                }
            }
        } while (changed);
    }

    private listenMyImunifyState(): void {
        this.myImunifyService.getMyImunifyState().pipe(
            takeUntil(this.destroyed),
        ).subscribe(myImunifyState => {
            this.myImunifyState = myImunifyState;
            this.cdr.markForCheck();
        });
    }

    private getSeparatorWidth() {
        return this.separator.nativeElement.offsetWidth;
    }
    private isMainNavHeightOk() {
        return this.navWrapper.nativeElement.offsetHeight <= 85;
    }
}
