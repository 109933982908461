import { Injectable } from '@angular/core';
import { AgentStatus, WindowInterface } from '@imunify360-api/misc';
import { NewsAPI, NewsItem } from '@imunify360-api/news';
import { of, Observable, EMPTY } from 'rxjs';
import { combineLatest, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { XHR } from 'app/utils/xhr/xhr';
import { AppState } from 'app/core/app.service';
import { AuthState } from 'app/services/auth-state';
import { I360Role } from 'app/services/auth';


declare var window: WindowInterface;

@Injectable()
export class NewsService {
    constructor(
        public xhr: XHR,
        public appState: AppState,
        public authState: AuthState,
    ) {}

    private storageKey: string = 'imunify-news-ids';
    private getNews = this.xhr.rx(NewsAPI.getNews, false, false);

    getNews$(): Observable<NewsItem[]> {
        const skipLoading = window.i360Test && window.i360Test.disableNews;
        if (skipLoading) {
            return of([]);
        }

        return this.authState.role.pipe(
            combineLatest(this.appState.imunifyStatus),
            switchMap(([role, status]) => {
                return role !== I360Role.none && status === AgentStatus.OK ? this.getNews() : EMPTY;
            }),
            map((response) => response.data.items),
            takeUntil(this.authState.role.pipe(filter(role => role === I360Role.none))),
        )
    }

    saveAsRead(id: string) {
        const readMessages = this.getReadMessages();
        readMessages.add(id);
        localStorage.setItem(this.storageKey, JSON.stringify([...readMessages.values()]));
    }

    isRead(id: string) {
        const readMessages = this.getReadMessages();

        return readMessages.has(id);
    }

    private getReadMessages() {
        return new Set<string>(
            JSON.parse(localStorage.getItem(this.storageKey) || '[]'),
        );
    }
}
