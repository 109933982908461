import { Injectable, OnDestroy } from '@angular/core';
import { identity ,  of ,  BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { TranslateService } from 'app/utils/translate/translate.service';


@Injectable()
export class CountryService implements OnDestroy {
    public static codes: string[] = [
        'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX',
        'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ',
        'BR', 'BS', 'BT', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL',
        'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO',
        'DZ', 'EC', 'EE', 'EG', 'ER', 'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB',
        'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU',
        'GW', 'GY', 'HK', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR',
        'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW',
        'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC',
        'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT',
        'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP',
        'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS',
        'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG',
        'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ',
        'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW',
        'TZ', 'UA', 'UG', 'UM', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF',
        'WS', 'XK', 'YE', 'YT', 'ZA', 'ZM', 'ZW',
    ];

    loaded = new BehaviorSubject(false);
    countries: {[key: string]: string} = {};
    countriesTranslated: {[key: string]: string};
    private langChangeSubscription = this.translate.translate.onLangChange.subscribe(event => {
        this.init();
    });
    constructor(private translate: TranslateService) {
        for (const code of CountryService.codes) {
            this.countries[code] = `country.${code}`;
        }

        this.init();
    }

    async init() {
        const countriesTranslated = {};
        for (const [code, key] of Object.entries(this.countries)) {
            countriesTranslated[code] = await this.translate.t(key);
        }
        this.countriesTranslated = countriesTranslated;
        this.loaded.next(true);
    }

    ngOnDestroy() {
        this.langChangeSubscription.unsubscribe();
    }

    searchCountryCodeByName(name: string): string {
        for (const [code, value] of Object.entries(this.countriesTranslated)) {
            if (value.toLowerCase() === name.toLowerCase()) {
                return code;
            }
        }

        return '';
    }

    getCountryNameByCode(code: string): string {
        return this.countriesTranslated[code] || '';
    }

    validator(required) {
        const noExisting = of({country: {}});
        const countryRequired = of({required: {what: 'country'}});
        const ofNull = of(null);
        const validate = (value) => this.searchCountryCodeByName(value) ? ofNull : noExisting;

        return ({value}) => {
            if (!value) {
                return required ? countryRequired : ofNull;
            }

            if (this.loaded.getValue()) {
                return validate(value);
            } else {
                return this.loaded.pipe(
                    first(identity),
                    map(() => validate(value)),
                );
            }
        };
    }

    async translateCountryInUrl(urlParam) {
        let countryCode = '';
        if (this.countriesTranslated && urlParam && this.getCountryNameByCode(urlParam)) {
            countryCode = await this.translate.t(`country.${urlParam}`);
        }
        return countryCode;
    }

    getCountryCode(text) {
        text = text.toLowerCase();
        return Object.keys(this.countriesTranslated).find(key => {
            return this.countriesTranslated[key].toLowerCase() === text;
        });
    }
}

