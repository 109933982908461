import { Inject, Injectable, InjectionToken, NgZone, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { FormGroupConfig, I360FormGroup } from 'app/utils/validator/form-group';
import { I360FormBuilder } from 'app/utils/validator/form-builder';
import { SettingsService } from 'app/services/settings';
import { ConfigRef } from 'app/core/config-ref';
import { TranslateService } from '@ngx-translate/core';
import { configsEqual } from 'app/utils/configs-equal';
import { LicenseState } from 'app/services/license-state';
import { testMode } from 'app/services/misc';
import { RequestsState } from 'app/services/requests-state';
import { AdminConfigType } from './admin-config';

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
export const GetConfigSchema = new InjectionToken('GetConfigSchema');
export type GetSchemaFn = <E>(builder: I360FormBuilder)
    => FormGroupConfig<E>;


@Injectable()
export class I360Config implements OnDestroy {
    destroyed = new Subject();
    changes: Observable<AdminConfigType> = this.requestsState.config.cache.pipe(
        takeUntil(this.destroyed),
    );
    schema: I360FormGroup<AdminConfigType, FormGroupConfig<AdminConfigType>>;
    constructor(private settingsService: SettingsService,
                private zone: NgZone,
                private formBuilder: I360FormBuilder,
                public translateService: TranslateService,
                public requestsState: RequestsState,
                public licenseState: LicenseState,
                private configRef: ConfigRef,
                @Inject(GetConfigSchema) private getSchema: GetSchemaFn) {
        configRef.setConfig(this);
        this.resetSchema();
    }

    /**
     * there are memory leaks in angular/forms,
     * need to reset model after creating new controller
     */
    resetSchema() {
        this.schema = this.formBuilder.group(this.getSchema(this.formBuilder));
    }

    save(dirty: boolean = true) {
        const values = dirty ? this.schema.getDirtyValues() : this.schema.value;
        return this.settingsService.saveSettings(values).pipe(
            tap(result => {
                this.schema.markAsPristine({children: true});
                this.zone.runOutsideAngular(() =>
                    this.requestsState.config.push.next(result.data.items as AdminConfigType));
            }),
        );
    }

    ngOnDestroy() {
        this.destroyed.next();
    }

    public formConfirmed(): boolean {
        if (this.licenseState.license.value.status &&
                this.isNotTest() &&
                this.configChanged() &&
                this.confirmNeeded()
        ) {
            return confirm(this.translateService.instant('reload-page'));
        }
        return true;
    }

    private configChanged() {
        const originalConfig = this.configRef._configChange.getValue();
        if (!originalConfig) {
            return false;
        }
        return !configsEqual(originalConfig, this.schema.value);
    }

    private isNotTest() {
        return !testMode('I360Config');
    }

    private confirmNeeded() {
        return !localStorage.getItem('I360_UNSAVED_CONFIG_LEAVING_PAGE_CONFIRM_DISABLED');
    }
}
