import { MaliciousFileStatus } from '../malicious';
import { DEFAULT_DATA } from './misc';
import { ResourceType } from '../malware';

export const simpleItem = {
    id: 1,
    username: 'marco1982',
    file: '/home/marco1983/public_html/tools/ksjbrvgiwgevbou.php',
    resource_type: ResourceType.file as ResourceType.file,
    created: 1488180905,
    size: 100,
    hash: '100',
    extra_data: {},
    malicious: 0,
    type: 'YARA.WebShell_cgi.UNOFFICIAL',
    status: MaliciousFileStatus.found,
    scan_type: 'on-demand',
    cleaned_at: null,
};
export const simpleItemDb = {
    id: 1,
    username: 'marco1982',
    file: '/home/marco1983/public_html/tools/ksjbrvgiwgevbou.php',
    resource_type: ResourceType.db as ResourceType.db,
    created: 1488180905,
    size: 100,
    hash: '100',
    extra_data: {},
    malicious: 0,
    type: 'YARA.WebShell_cgi.UNOFFICIAL',
    status: MaliciousFileStatus.found,
    scan_type: 'on-demand',
    cleaned_at: null,
    app_name: 'mysql',
    db_host: 'localhost',
    db_port: '8888',
    db_name: 'test',
};


export const responses = {
    list: {
        data: {
            ...DEFAULT_DATA,
            max_count: 20,
            malicious_count: 100,
            items: [
                {
                    ...simpleItem,
                    status: MaliciousFileStatus.cleanup_started,
                },
                {
                    ...simpleItem,
                    id: 2,
                    scan_type: 'realtime',
                },
                {
                    ...simpleItemDb,
                    id: 3,
                    type: '64444 - Exec.request',
                },
                {
                    ...simpleItem,
                    id: 4,
                    type: '64444 - Exec.request',
                },
                {
                    ...simpleItem,
                    id: 5,
                    status: MaliciousFileStatus.quarantined,
                },
                {
                    ...simpleItem,
                    id: 6,
                    status: MaliciousFileStatus.cleanup_done,
                    cleaned_at: 1536062665,
                },
                {
                    ...simpleItem,
                    id: 7,
                    status: MaliciousFileStatus.cleanup_removed,
                    cleaned_at: 1536062665,
                },
                {
                    ...simpleItem,
                    id: 8,
                    status: MaliciousFileStatus.cleanup_requires_myimunify_protection,
                    cleaned_at: 1536062665,
                },
            ],
        },
    },

    listDBTypes: {
        data: {
            ...DEFAULT_DATA,
            max_count: 20,
            malicious_count: 100,
            items: [
                {
                    ...simpleItemDb,
                    status: MaliciousFileStatus.cleanup_started,
                },
                {
                    ...simpleItemDb,
                    id: 2,
                    status: MaliciousFileStatus.cleanup_removed,
                },
                {
                    ...simpleItemDb,
                    id: 3,
                    status: MaliciousFileStatus.cleanup_done,
                    cleaned_at: 1536062665,
                },
                {
                    ...simpleItemDb,
                    id: 4,
                    status: MaliciousFileStatus.cleanup_removed,
                    cleaned_at: 1536062665,
                },
                {
                    ...simpleItemDb,
                    id: 5,
                    type: '64444 - Exec.request',
                },
            ],
        },
    },

    restore: {
        data: {
            ...DEFAULT_DATA,
            failed: [
                '/some/file/that/was/not/restored',
                '/another/file/that/was/not/restored',
            ],
            succeeded: [
                '/some/file/that/was/restored',
                '/another/file/that/was/restored',
            ],
        },
    },
};
