import { CommandReport, WindowInterface } from '@imunify360-api/misc';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpEvent, HttpHandler, HttpInterceptor,
    HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Inject, Optional, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs';
declare const window: WindowInterface;
declare const jQuery: any;
declare const Prototype: any;
jQuery.noConflict();

if (typeof Prototype !== 'undefined' && Prototype.BrowserFeatures.ElementExtensions) {
    let disablePrototypeJS = (method, pluginsToDisable) => {
            pluginsToDisable.each(function (plugin) {
                jQuery(window).on(method + '.bs.' + plugin, (event) => {
                    event.target[method] = undefined;
                    setTimeout(() => {
                        delete event.target[method];
                    }, 0);
                });
            });
        },
        pluginsToDisable = ['collapse', 'dropdown', 'modal', 'tooltip', 'popover', 'tab'];
    disablePrototypeJS('show', pluginsToDisable);
    disablePrototypeJS('hide', pluginsToDisable);
}



export function loadTestInterceptor() {
    window.i360Test = {
        type: 'e2e',
        disableNews: true,
        disableStats: true,
        testMode: {},
        lastRequest: {} as CommandReport,
        requests: {},
        requestsHistory: [],
        mock: [],
        jq: jQuery,
    };

    jQuery(function() {
        // remove header in plesk
        // it blocks clicks in navigation tests
        jQuery('.page-header-wrapper').css({visibility: 'hidden'});
    });
}

export function bootstrapApp(main: Function) {
    function __domReadyHandler() {
        document.removeEventListener('DOMContentLoaded', __domReadyHandler);
        main();
    }
    switch (document.readyState) {
        case 'loading':
            document.addEventListener('DOMContentLoaded', __domReadyHandler);
            break;
        case 'interactive':
        case 'complete':
        default:
            main();
    }
}

declare const __webpack_public_path__: string;
export const getImagePath = () => `${__webpack_public_path__}images`;

export function enableSvgIcons(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
) {
    const imagePath = getImagePath();
    const icons = [
        'fish',
        'aquarium',
        'add-ip',
        'ignore-all',
        'broom',
        'bell',
        'atoms',
        'done',
        'chip',
        'update',
        'clock',
        'dmc',
        'play',
        'full-access',
        'icon-exclamation',
        'icon-exclamation-red',
        'logo-grey',
        'ellipsis',
        'storage',
        'shark',
        'vector',
        'logo-imunify',
        'logo-myimunify',
        'new-features-management',
        'profile',
        'rocket',
        'support',
        'logo-imunifyAV',
        'logo-imunifyAVPlus',
        'logo-imunifyAVPlus-grey',
        'key',
        'help-outline-black',
        'icon-question',
        'icon-delete',
        'icon-add',
        'icon-key',
        'icon-bell-in-circle',
        'icon-info',
        'dashboard-no-data-found',
        'read-more',
        'events-notifications',
        'check-circle',
        'shield',
        'shield-gray',
        'shield-malware',
        'undraw-server-down',
        'upgrade-by-key',
    ];
    for (const icon of icons) {
        iconRegistry.addSvgIcon(icon, sanitizer.bypassSecurityTrustResourceUrl(
            `${imagePath}/${icon}.svg`,
        ));
    }
}

export class AngularIssues18894Crutch implements HttpInterceptor {
    constructor(private interceptors: HttpInterceptor[]) {}
    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        return (this.interceptors || []).reduceRight(
            (next, interceptor) => ({
                // HttpInterceptorHandler not exported, so use duck typing
                handle(req: HttpRequest<any>) {
                    return interceptor.intercept(req, next);
                },
            }), next).handle(req);
    }
}

export function angular18894CrutchFactory(interceptors: HttpInterceptor[]) {
    return new AngularIssues18894Crutch(interceptors);
}
export const angular18894CrutchProvider = {
    // https://github.com/angular/angular/issues/18894
    provide: HTTP_INTERCEPTORS,
    multi: true,
    useFactory: angular18894CrutchFactory,
    deps: [[new SkipSelf, new Optional, new Inject(HTTP_INTERCEPTORS)]],
};


/**
 * A hack to prevent Plesk from adding a csrf header to some requests.
 */
export function applyCsrfStrategy() {
    const csrfTokenMeta = document.getElementById('forgery_protection_token');
    const urls = ['https://api.imunify360.com', 'https://sentry.cloudlinux.com'];
    const oldSend = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function (...args) {
        let skipHeader = false;
        try {
            if (urls.find(url => (this.__zone_symbol__xhrURL as string).startsWith(url))) {
                skipHeader = true;  // this request shouldn't contain csrf header
            }
        } catch (e) { }
        if (skipHeader && csrfTokenMeta) {
            csrfTokenMeta.setAttribute('id', 'temporary-changed-for-sentry-request');
            const retVal = oldSend.apply(this, args);
            csrfTokenMeta.setAttribute('id', 'forgery_protection_token');
            return retVal;
        } else {
            return oldSend.apply(this, args);
        }
    };
}
