import { Injectable } from '@angular/core';
import { I360Config } from 'app/utils/config/config';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AdminConfigType } from 'app/utils/config/admin-config';



@Injectable()
export class ConfigRef {
    config: I360Config;
    _configChange = new BehaviorSubject<AdminConfigType | null>(null);
    configChange: Observable<AdminConfigType> = this._configChange.pipe(
        // typescript can not determine if function is predicate..
        filter((data): data is AdminConfigType => data !== null),
    );
    constructor() {}

    setConfig(config: I360Config) {
        this.config = config;
        config.changes.subscribe((config) => {
            this._configChange.next(config);
        });
    }
}
