import { Injectable, OnDestroy } from '@angular/core';
import { XHR } from 'app/utils/xhr/xhr';
import { Observable, noop, timer, of } from 'rxjs';
import {
    MyImunifyAPI,
    MyImunifyResult,
} from '@imunify360-api/myimunify';
import { AuthState } from 'app/services/auth-state';
import { switchMap, map, take, catchError, takeUntil } from 'rxjs/operators';
import { MyImunifyState, IMyImunifyState } from 'app/services/my-imunify-state';
import { NotificationsService } from 'app/services/notifications';
import { Package } from '@imunify360-api/license';

export const MYIMUNIFY_UPDATE_INTERVAL = 10000;

@Injectable()
export class MyImunifyService implements OnDestroy {
    status = this.xhr.rx(MyImunifyAPI.status);
    update = this.xhr.rx(MyImunifyAPI.update);
    enableProtectionForAll = this.xhr.rx(MyImunifyAPI.enableAll);

    constructor(
        private xhr: XHR,
        private authState: AuthState,
        private state: MyImunifyState,
        private notifications: NotificationsService,
    ) {}

    ngOnDestroy(): void {
        this.state.myImunifyStateSubscription?.unsubscribe();
        this.state.myImunifyStateSubscription = undefined;
    }

    openUpgradePage(): void {
        this.getMyImunifyState().pipe(take(1)).subscribe(state => {
            if (!state.purchase_page_url) {
                this.notifications.error('notifications.myImunifyPageNotSpecified');
            } else {
                window.open(this.getFixedPurchaseUrl(state.purchase_page_url), '_blank');
            }
        });
    }

    getMyImunifyState(): Observable<IMyImunifyState> {
        if (!this.state.myImunifyStateSubscription && !window['MYIMUNIFY_DISABLED']) {
            this.state.myImunifyStateSubscription = this.authState.isClient
                .pipe(
                    switchMap(isClient => {
                        if (isClient && IMUNIFY_PACKAGE === Package.imunify360) {
                            return this.poolMyImunifyStatus();
                        } else {
                            this.state.myImunifyStateSubscription?.unsubscribe();
                            this.state.myImunifyStateSubscription = undefined;
                            return of(null);
                        }
                    }),
                )
                .subscribe({ next: noop, error: noop });
        }

        return this.state.changes$.asObservable();
    }

    private poolMyImunifyStatus(): Observable<void> {
        const params = {items: []};

        return timer(0, MYIMUNIFY_UPDATE_INTERVAL).pipe(
            switchMap(() => this.status(params)),
            catchError(() => of(undefined)),
            map(response => this.handleMyImunifyStatusResult(response?.data)),
            takeUntil(this.state.isEnabledWithProtection$),
        );
    }

    private handleMyImunifyStatusResult(result: MyImunifyResult | undefined): void {
        if (!result) return;

        const state = this.state.changes$.getValue();
        const item = result.items.length ? result.items[0] : null;

        if (state.enabled !== result.myimunify_enabled ||
            state.protection !== item?.protection ||
            state.purchase_page_url !== result.purchase_page_url) {

            this.state.changes$.next({
                enabled: result.myimunify_enabled,
                protection: !!item?.protection,
                purchase_page_url: result.purchase_page_url
            });

            if (result.myimunify_enabled && !!item?.protection) {
                this.state.isEnabledWithProtection$.next();
            }
        }
    }

    private getFixedPurchaseUrl(urlToFix: string): string {
        if (urlToFix.startsWith('http')) {
            return urlToFix;
        }

        return 'https://' + urlToFix;
    }
}
