import { Component, Optional, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AuthState } from 'app/services/auth-state';
import { Panel } from 'app/utils/panel';
import { ReputationService } from 'app/services/reputation';
import { LicenseState } from 'app/services/license-state';
import { RequestsState } from 'app/services/requests-state';
import { EulaService } from 'app/components/eula/eula.service';
import { I360Config } from 'app/utils/config/config';
import { AppState } from 'app/core/app.service';
import { fromEvent, Subject } from 'rxjs';
import { I360ConfigDataItems } from '@imunify360-api/settings';
import { bufferCount, filter, map, takeUntil } from 'rxjs/operators';
import { EmailsService } from 'app/services/emails';
import { AuthService, I360Role } from "app/services/auth";
import { Product, Package } from '@imunify360-api/license';
import { UserFeaturesStatusService } from 'app/services/user-features-status';
import { NavigationEnd, Router } from '@angular/router';
import { AgentStatus } from '@imunify360-api/misc';
import { MyImunifyService } from 'app/services/my-imunify';
import { IMyImunifyState } from 'app/services/my-imunify-state';
import { FirewallService } from 'app/components/lists/firewall.service';

@Component({
    selector: 'nav-root',
    templateUrl: './nav-root.component.html',
    providers: [
        I360Config,
    ],
    styleUrls: ['./nav-root.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavRootComponent implements OnDestroy {
    support = this.config.changes.pipe(
        map((c: I360ConfigDataItems) =>  c.PERMISSIONS.support_form),
    );
    imunifyPackage = IMUNIFY_PACKAGE;
    AgentStatus = AgentStatus;
    Product = Product;
    userRole: I360Role = this.authState.role.value;
    isClient: boolean;
    isAdmin: boolean;
    myImunifyState: IMyImunifyState;

    private destroy$ = new Subject<void>();

    constructor(
        private cdr: ChangeDetectorRef,
        public panel: Panel,
        public firewallService: FirewallService,
        public emailsService: EmailsService,
        public authState: AuthState,
        public authService: AuthService,
        public appState: AppState,
        public licenseState: LicenseState,
        public requestsState: RequestsState,
        @Optional() public eulaService: EulaService,
        // looks like component level providers are lazy loaded
        @Optional() public reputationService: ReputationService,
        public config: I360Config,
        userFeaturesStatusService: UserFeaturesStatusService,
        private router: Router,
        public myImunifyService: MyImunifyService,
    ) {
        if (authState.role.value === I360Role.none) {
            authService.goToLoginPage();
        }

        // Need for login
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.userRole = this.authState.role.value;
            }
        })

        //  Need for iframe in DirectAdmin panel
        if (this.panel.isDirectAdmin && document.querySelector('#iframe-container')) {
            fromEvent(window, 'mousemove').pipe(
                map((event: MouseEvent) => event.clientY),
                bufferCount(20, 1),
                filter((clientY) => {
                    return ((this.appState.mousePositonY + 30  < clientY[0]) ||
                        (this.appState.mousePositonY - 30 > clientY[0]));
                }),
                takeUntil(this.destroy$),
            ).subscribe((position) => {
                this.appState.mousePositonY = position[0];
            });
        }

        this.authState.isClient.pipe(takeUntil(this.destroy$)).subscribe(isClient => {
            this.isClient = isClient;
            this.cdr.markForCheck();
        });
        this.authState.isAdmin.pipe(takeUntil(this.destroy$)).subscribe(isAdmin => {
            this.isAdmin = isAdmin;
            this.cdr.markForCheck();
        });
        if (this.imunifyPackage === Package.imunify360) {
            this.listenMyImunifyState();
        }
    }

    private listenMyImunifyState(): void {
        this.myImunifyService.getMyImunifyState()
            .pipe(takeUntil(this.destroy$))
            .subscribe(state => {
                this.myImunifyState = state;
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
