import { Component, Injector, OnDestroy, OnInit,
    ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Package } from '@imunify360-api/license';
import { of, BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { I360Config } from 'app/utils/config/config';
import { NewsItemDirective } from '../news-item.directive';
import { AdminContactsHistoryService } from './admin-contacts-history.service';
import { AdminContactsOpenerInitialService } from './admin-contacts-opener-initial.service';
import { AdminContactsOpenerSequentialService } from './admin-contacts-opener-sequential.service';
import { AdminContactsOpenerNeverService } from './admin-contacts-opener-never.service';
import { AdminContactsOpenerPeriodicalService } from './admin-contacts-opener-periodical.service';
import { AdminContactsOpenerService } from './admin-contacts-opener.service';
import {
    maxNotificationsCount,
    sequentialNotificationsCount,
} from './news-admin-contacts.constants';

export function adminContactsOpenerServiceFactory(
    injector: Injector,
    adminContactsHistoryService: AdminContactsHistoryService,
) {
    const history = adminContactsHistoryService.getHistory();

    if (history.length === 0) {
        return injector.get(AdminContactsOpenerInitialService);
    } else if (history.length < sequentialNotificationsCount) {
        return injector.get(AdminContactsOpenerSequentialService);
    } else if (history.length < maxNotificationsCount) {
        return injector.get(AdminContactsOpenerPeriodicalService);
    } else {
        return injector.get(AdminContactsOpenerNeverService);
    }
}

@Component({
    selector: 'i360-news-admin-contacts',
    template: `
        <ng-template #tpl>
            <div *ngIf="isCountable | async" class="event-item i360-admin-contacts">
                <i class="material-icons feed">rss_feed</i>
                <div>
                    <p class="attention i360-admin-contacts_message">
                        {{ 'news.adminContacts.notificationText' | translate }}
                    </p>
                    <p class="i360-admin-contacts_link">
                        <a class="i360-license_redirect-link"
                           [routerLink]="['/', imunify360Package, 'admin', 'settings', 'general']">
                            {{ 'news.adminContacts.specifyEmail' | translate }}
                        </a>
                    </p>
                </div>
            </div>
        </ng-template>
    `,
    styleUrls: ['./news-admin-contacts.component.scss'],
    encapsulation: ViewEncapsulation.None,  // because we use ngTemplateOutlet in news-notifications
    providers: [
        { provide: NewsItemDirective, useExisting: NewsAdminContactsComponent },
        AdminContactsHistoryService,
        AdminContactsOpenerInitialService,
        AdminContactsOpenerSequentialService,
        AdminContactsOpenerPeriodicalService,
        AdminContactsOpenerNeverService,
        {
            provide: AdminContactsOpenerService,
            useFactory: adminContactsOpenerServiceFactory,
            deps: [Injector, AdminContactsHistoryService],
        },
    ],
})
export class NewsAdminContactsComponent extends NewsItemDirective implements OnInit, OnDestroy {
    destroyed = new Subject;
    isCountable = TEST && window['i360Test'] && window['i360Test'].disableNews
        ? of(false) : this.i360Config.changes.pipe(
            map((config) => !config.ADMIN_CONTACTS || config.ADMIN_CONTACTS.emails.length === 0),
            takeUntil(this.destroyed),
        );
    isUnread = this.isCountable;
    openOverlay = new BehaviorSubject(false);
    imunify360Package = Package.imunify360;

    constructor(
        private i360Config: I360Config,
        private notificationsOpenerService: AdminContactsOpenerService,
        public viewContainerRef: ViewContainerRef,
    ) {
        super();

        this.blockOverlayAutoOpen = new BehaviorSubject(
            this.notificationsOpenerService.blockOverlayAutoOpen,
        );
    }

    ngOnInit(): void {
        this.notificationsOpenerService.init(this);
        this.notificationsOpenerService.openIfNeeded();
    }

    ngOnDestroy() {
        this.destroyed.next();
    }

    markAsRead(): void {}
}
