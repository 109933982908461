import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthState } from 'app/services/auth-state';
import { postOnly } from 'app/interceptors/utils';
import { I360Role } from 'app/services/auth';
import { filter, switchMap, take } from 'rxjs/operators';

declare const clientAction: string;
declare const adminAction: string;

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
    constructor(
        private authState: AuthState,
    ) {}

    @postOnly
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // this code should be present in xhr.
        if (req.url.startsWith('http')) {  // role matters only for requests to the Agent
            return next.handle(req);
        }
        return this.authState.role.pipe(
            // `UrlInterceptor` may get triggered in the middle of `AuthState.role.next(...)` call.
            // Make sure it waits until the role is either `admin` or `client` before choosing
            // the `adminAction`/`clientAction` base URL.
            // Login requests can be performed regardless of the role.
            filter(role => role !== I360Role.none || req.body?.method?.[0] === 'login'),
            take(1),
            switchMap(role => {
                const newUrl = (role === I360Role.admin ? adminAction : clientAction) + req.url;
                return next.handle(req.clone({url: newUrl}))
            }),
        );
    }
}
