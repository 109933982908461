import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { XHR } from 'app/utils/xhr/xhr';
import { ListsAPI } from '@imunify360-api/lists';
import { take } from 'rxjs/operators';
import { FirewallState } from 'app/components/lists/firewall-state.service';

@Injectable({ providedIn: 'root' })
export class FirewallService implements CanActivate {
    constructor(private xhr: XHR, private firewallState: FirewallState) {
    }

    canActivate(): Observable<boolean> {
        return this.isFirewallEnabled();
    }

    isFirewallEnabled(): Observable<boolean> {
        const isFirewallDisabled = window['IMUNIFY_FIREWALL_DISABLED'];
        if (isFirewallDisabled === true || isFirewallDisabled === false) {
            return of(!isFirewallDisabled);
        }

        if (this.firewallState.checkStarted) {
            return this.firewallState.isFirewallEnabled$.asObservable();
        }

        this.firewallState.checkStarted = true;
        this.xhr.rx(ListsAPI.getAllList, false, false)({ params: { limit: 1 } })
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.firewallState.isFirewallEnabled$.next(true);
                },
                error: err => {
                    const messages = err?.messages || [];
                    if (messages.includes('Not available in the current build')) {
                        this.firewallState.isFirewallEnabled$.next(false);
                    } else {
                        this.firewallState.isFirewallEnabled$.next(true);
                    }
                }
            });

        return this.firewallState.isFirewallEnabled$.asObservable();
    }
}
